import React from 'react';
import { Box, Paper, CircularProgress, Pagination, PaginationItem, Stack, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Chip } from '@mui/material';
import { myReconocimientoInterface } from '../../../common/interfaces/ReconocimientoInterface';
import Swal from 'sweetalert2';
import { formatDate } from '../../../utils/datesUtils';
import { ThemeContext } from '../../../context/ThemeContext';
import { IReconocimientos } from '../interfaces/Reconocimientos';
import { LanguageContextInterface } from '../../../common/interfaces/LanguageInterface';
import { formatNumber } from '../../../utils/functions';
import { ArrowBack, ArrowDropDown, ArrowDropUp, ArrowForward, ImportExport } from '@mui/icons-material';
import './reconocimientos.styles.css';

type Props = {
  recoRealizados: myReconocimientoInterface[] | null;
  loading: boolean;
  totalPages: number;
  page: number;
  orden: { campo: string; ascendente: boolean };
  dictionary?: IReconocimientos;
  setPage: (page: number) => void;
  cambiarOrdenamiento: (campo: string) => void;
  language: LanguageContextInterface;
};

export const ReconocimientosRealizadosTable: React.FC<Props> = ({
  recoRealizados,
  loading,
  totalPages,
  page,
  orden,
  dictionary,
  language,
  setPage,
  cambiarOrdenamiento,
}) => {
  const theme = React.useContext(ThemeContext);

  const handleChangePag = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const verDetalle = (persona: string, descripcion: string, idioma: string, imagen: string) => {
    Swal.fire({
      position: 'top',
      title: persona + (idioma === 'ES' ? ' comentario:' : "'s comment"),
      text: descripcion,
      imageUrl: imagen,
      showCancelButton: true,
      showConfirmButton: false,
      customClass: {
        popup: 'myreco-swal-container',
        title: 'myreco-swal-title',
        cancelButton: 'myreco-btn',
      },
    });
  };

  return (
    <Box marginTop={3}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#E9E9E9' }}>
              {[
                {
                  label: 'ID',
                  campo: 'idReconocimiento',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.fecha,
                  campo: 'fecha',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.tipoReconocimiento,
                  campo: 'tituloTipoReconocimiento',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.empleadoReconocido,
                  campo: 'nombreUsuarioReconocido',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.supervisor,
                  campo: 'nombreUsuarioSupervisor',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.puntos,
                  campo: 'puntos',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.estado,
                  campo: 'estado',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.fechaAprobacion,
                  campo: 'fechaAprobacion',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.realizados.datatable.comentario,
                  campo: 'descripcion',
                  hasOrdering: false,
                },
              ].map((columna) => (
                <TableCell
                  align="center"
                  key={`header-${columna.campo}`}
                  sx={{ fontSize: 12, fontWeight: 'bold', cursor: 'pointer' }}
                  onClick={() => columna?.hasOrdering && cambiarOrdenamiento(columna.campo)}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    {columna.label}
                    {columna?.hasOrdering ? (
                      orden.campo === columna.campo ? (
                        orden.ascendente ? (
                          <ArrowDropUp fontSize="small" />
                        ) : (
                          <ArrowDropDown fontSize="small" />
                        )
                      ) : (
                        <ImportExport fontSize="small" />
                      )
                    ) : null}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                  <CircularProgress />
                  <div>{dictionary?.cargando}</div>
                </TableCell>
              </TableRow>
            ) : recoRealizados && recoRealizados.length > 0 ? (
              recoRealizados.map((row, index) => (
                <TableRow key={`reco-realizado-${row.idMovimiento}-${index}`}>
                  <TableCell component="th" scope="row">
                    {row.idReconocimiento}
                  </TableCell>
                  <TableCell align="center">{formatDate(row.fecha)}</TableCell>
                  <TableCell align="center">{row.tituloTipoReconocimiento}</TableCell>
                  <TableCell align="center">{row.nombreUsuarioReconocido}</TableCell>
                  <TableCell align="center">{row.nombreUsuarioSupervisor}</TableCell>
                  <TableCell align="center">{row.puntos === 0 ? '-' : formatNumber(row.puntos)}</TableCell>
                  <TableCell align="center">
                    <Chip
                      color={row.estado === 'APROBADO' ? 'success' : 'warning'}
                      label={
                        row.estado === 'APROBADO'
                          ? dictionary?.datatable.aprobado
                          : dictionary?.datatable.pendienteAprobacion
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    {!row.fechaAprobacionString || row.fechaAprobacionString.length === 0
                      ? '-'
                      : row.fechaAprobacionString}
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      onClick={() =>
                        verDetalle(row.nombreUsuarioAsignador, row.descripcion, language.language, row.imagen)
                      }
                      color="primary"
                      label={dictionary?.datatable.verDetalles}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={8}>
                  {dictionary?.datatable.sinResultados}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 3,
        }}
      >
        <Stack spacing={2}>
          <Pagination
            color="primary"
            shape="rounded"
            variant="outlined"
            page={page}
            count={totalPages}
            onChange={handleChangePag}
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: ArrowBack, next: ArrowForward }}
                {...item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: theme?.theme && theme?.theme[10] ? theme?.theme[10].color : 'primary', // Color de fondo para el número de página seleccionado
                    color: 'white', // Color del texto para el número de página seleccionado
                  },
                }}
              />
            )}
          />
        </Stack>
      </Box>
    </Box>
  );
};
